import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <b><code>Dev-Optima</code></b>
          {/*<b>Dev-Optima</b>*/}
        </p>
        <a
          className="App-link"
          href="mailto:yurii.averin@dev-optima.com"
        >
          <code>Yurii.Averin@dev-optima.com</code>
          {/*Yurii.Averin@dev-optima.com*/}
        </a>
      </header>
    </div>
  );
}

export default App;
